import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {  OnInit, OnDestroy } from '@angular/core';
import { InactivityService } from './core/user/inactivity.service';
import { Subscription } from 'rxjs';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy
{
    private inactivitySubscription: Subscription;

    constructor(private inactivityService: InactivityService,
        private _router: Router ) {}
  
    ngOnInit() {
      this.inactivitySubscription = this.inactivityService.startWatching().subscribe(() => {
        this.logoutUser();
      });
    }
  
    ngOnDestroy() {
      this.inactivitySubscription.unsubscribe();
      this.inactivityService.stopWatching();
    }
  
    private logoutUser(): void {
      // Aqui você implementa a lógica de logout
      console.log('User has been logged out due to inactivity.');
      // Redirecione para a página de login ou outra ação de logout
      this._router.navigate(['/sign-out']);
    }
}
