/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'folhasdeobra',
        title: 'Folhas de Obra',
        type : 'basic',
        icon : 'no_transfer',
        link : '/folhasobras'
    },
    {
        id   : 'AgendaFuncionario',
        title: 'Agenda',
        type : 'basic',
        icon : 'mat_outline:calendar_today',
        link : '/agenda/Funcionario'
    },
    {
        id   : 'docs',
        title: 'Changelog',
        type : 'basic',
        icon : 'heroicons_outline:chat-bubble-left-ellipsis',
        link : '/docs/changelog'
    }, {
        id   : 'settings',
        title: 'Settings',
        type : 'spacer',
    //    icon : 'heroicons_outline:cog',
    //    link : '/pages/settings'
    },
     {
        id   : 'settings2',
        title: 'Settings2',
        type : 'spacer',
    //    icon : 'heroicons_outline:cog',
     //   link : '/pages/settings'
    },
    {
        id   : 'terminar',
        title:  'Terminar Sessão',
        type : 'basic',
        icon : 'heroicons_outline:arrow-right-on-rectangle',
        link : '/sign-out',
        function(item) {
            console.log(item);
            console.log('Terminar Sessão');
        },
   //     link : '/docs/changelog'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
