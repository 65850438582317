import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable, timer } from 'rxjs';
import { switchMap, tap, startWith, timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InactivityService {
    // private timeoutMilliseconds = 10 * 60 * 1000; // 10 minutos
    // 1 minuto
    private timeoutMilliseconds = 1 * 60 * 1000;
    private userActivity = new Subject<any>();
    private timeout$: Observable<any>;
    constructor(private ngZone: NgZone) {}
    public startWatching(): Observable<any> {
        this.setupActivityListeners();
        this.timeout$ = this.userActivity.pipe(
            startWith('initial trigger'),
            // Reinicia o timer a cada evento de atividade do usuário
            switchMap(() => {
                return timer(this.timeoutMilliseconds);
            }),
            tap(() => {
                console.log('Inactivity detected. Logging out.');
             //   alert('Inactivity detected. Logging out.');
            })
        );
        return this.timeout$;
    }

    private setupActivityListeners(): void {
        this.ngZone.runOutsideAngular(() => {
            ['click', 'mousemove', 'keydown', 'scroll'].forEach((event) => {
                window.document.addEventListener(
                    event,
                    () => this.userActivity.next(undefined),
                    true
                );
            });
        });
    }

    public stopWatching(): void {
        ['click', 'mousemove', 'keydown', 'scroll'].forEach((event) => {
            window.document.removeEventListener(
                event,
                () => this.userActivity.next(undefined),
                true
            );
        });
    }
}
