import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Signal,
    ViewEncapsulation,
} from '@angular/core';
import { LeitorCodigoService } from './leitor-codigo.service';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { JsonPipe, NgIf } from '@angular/common';
import { haveLeitor } from './_signals';
import { Subject, Subscription, takeUntil } from 'rxjs';
@Component({
    selector: 'leitor-estado',
    standalone: true,
    imports: [MatInput, MatIcon, NgIf, JsonPipe],
    templateUrl: './estadoleitor.component.html',
    styleUrl: './estadoleitor.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstadoleitorComponent implements OnInit, OnDestroy  {
    haveLeitor: Signal<boolean> = haveLeitor;
    estado: boolean;
    Pesquisa: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private _leitorCodigoService: LeitorCodigoService,
      private _changeDetectorRef: ChangeDetectorRef

    ) {}

    toggle() {
        // this._leitorCodigoService.toggleLeitor();
        this._leitorCodigoService.toggleLeitor();
    }

    ngOnInit(): void {
     this._leitorCodigoService. leitor$
     .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((estado) => {
            console.log('estado leitor$', estado);
            this.estado = estado;
            this._changeDetectorRef.markForCheck();
        });

        this._leitorCodigoService.OpenSearch$.pipe(takeUntil(this._unsubscribeAll)).subscribe((estado) => {
          console.log('estado OpenSearch$', estado);
            this.Pesquisa = estado;
            this._changeDetectorRef.markForCheck();
        } );
    }


    ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }
}
