import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LeitorCodigoService {
    private leitorSubject = new BehaviorSubject<boolean>(false);
    private searchSubject = new BehaviorSubject<boolean>(false);
    private searchRemote = new BehaviorSubject<boolean>(false);
    private txtProcura = new BehaviorSubject<string>('');
    private anyResultado= new BehaviorSubject<any[]>([]);

    leitor$ = this.leitorSubject.asObservable();
    OpenSearch$ = this.searchSubject.asObservable();
    searchRemote$ = this.searchRemote.asObservable();
    txtProcura$ = this.txtProcura.asObservable();
    anyResultado$ = this.anyResultado.asObservable();


    constructor( private cookieService: CookieService ) {

this.leitorSubject.next( this.cookieService.get('temLeitor') === 'ON' ? true : false );
    }


    inactivo() {
        console.log('>>>inactivo', this.leitorSubject.getValue());
        this.leitorSubject.next(false);
    }
    activar() {
        console.log('>>>activar', this.leitorSubject.getValue());
        this.leitorSubject.next(true);
    }
    abrePesquisa() {
console.log('abrePesquisa', this.searchSubject.getValue());

        const statusLeitor = this.leitorSubject.getValue();
        const statusPesquisa = this.searchSubject.getValue();
        if (statusLeitor) {
            console.log('Leitor Activo');
            if (statusPesquisa) {
                console.log('Pesquisa já esta aberta !!');
              
            }
            if (!statusPesquisa) {
                console.log('Abrir Pesquisa');
                this.searchRemote.next(true);
            }
        } else {
            console.log('Leitor desativado!');
        }
        
    }

    fechaPesquisa() {
  console.log('fechaPesquisa', this.searchSubject.getValue());
      
        const statusLeitor = this.leitorSubject.getValue();
        const statusPesquisa = this.searchSubject.getValue();
        if (statusLeitor) {
            console.log('Leitor Activo');
            if (statusPesquisa) {
                console.log('Fechar Pesquisa');
                this.searchRemote.next(false);
            }
            if (!statusPesquisa) {
                console.log('Pesquisa já está fechada !!');
            }
        } else {
            console.log('Leitor desativado!');
        }
      
    }

    toggleLeitor() {
        const estadoAtual = this.leitorSubject.getValue();
        this.leitorSubject.next(!estadoAtual);
    }

    regista(estado:boolean){
      console.log('Enviar o registo para o leitor-codigo.service.ts' , estado);
        this.searchSubject.next(estado);
    }
    estado()   {
      return this.leitorSubject.getValue();
    }
    setTxtProcura( txt: string ) {
        if('' === txt) {
            this.anyResultado.next([]);
        }
        this.txtProcura.next(txt);
        }
        
    getTxtProcura() {
       return this.txtProcura.getValue();
    }
    setPesquisa(resp:any[])
    {
        console.log('setPesquisa', resp);
        this.anyResultado.next(resp);
    }

}
