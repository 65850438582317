<ng-container>

    <button
        mat-icon-button
      
        (click)="toggle()">
        <mat-icon  *ngIf="estado" svgIcon="heroicons_outline:chart-bar-square"></mat-icon>
        <mat-icon *ngIf="!estado" svgIcon="heroicons_outline:minus-small"></mat-icon>
    </button>

</ng-container>

